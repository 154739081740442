.section
    animation: section__animation--in 0.5s ease-out

@keyframes section__animation--in
    0%, 40%
        opacity: 0
        transform: translateY(0.25rem)
    100%
        opacity: 1
        transform: translateY(0)

@media (prefers-reduced-motion)
    @keyframes section__animation--in
        0%, 40%
            opacity: 0
        100%
            opacity: 1